import axios from "axios";
const Axios = axios.create({
  // baseURL: 'http://localhost:8000/api/',  
  baseURL: 'https://9d24-152-58-34-241.ngrok-free.app/api/',
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "ngrok-skip-browser-warning": "true",
  },
  withCredentials: true,  
});

export default Axios;
